import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}

export function formatRelativeTime(timestamp: number | bigint | undefined): string {
  if (timestamp === undefined || timestamp === null) return ''
  const now = new Date()
  const timeDifference = now.getTime() - new Date(Number(timestamp) * 1000).getTime()

  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)

  if (days === 0) {
    if (hours === 0) return `${minutes} minutes ago`
    return `${hours} hours ago`
  } else if (days === 1) {
    return `yesterday ${new Date(Number(timestamp) * 1000).toLocaleTimeString([], { hour: 'numeric' })}`
  } else if (days < 7) {
    return `${days} days ago`
  } else if (weeks === 1) {
    return `last week`
  } else {
    return new Date(Number(timestamp) * 1000).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })
  }
}
